<template>
    <div class="filter">
        <div class="d-flex align-items-center">
            <label for="filter" class="font_size_label">{{ label }}</label>
            <feather-icon v-if="tooltip"
            v-b-tooltip.hover.right
            :title="tooltip"
            size="16" icon="HelpCircleIcon" class="help-icon" />
        </div>

        <div class="filter-field" @click="openDropdown()" :class="[`${className}-filter`, {'dropdown-open': dropdownExpanded}]">

            <input type="text"
                class="filter-input"
                :class="`${className}-display`"
                :value="optionName"
                :title="titleDisplay"
                :disabled="disabled"
                readonly>

            <transition name="fade">
                <ul class="filter-dropdown" :class="{'show': dropdownExpanded}" v-show="dropdownExpanded">

                    <li v-for="option in optionsDropdown" :key="option[field_id]"
                        class="dropdown-option"
                        :class="{'selected': checkSelected(option)}"
                        :title="setTitle(option)"
                        @click="toggleOption(option)">
                            <strong :class="{'text-white': checkSelected(option)}" v-if="option[field_label]">{{ option[field_label] }}<br></strong>
                            {{ setTitle(option) }}

                    </li>

                </ul>
            </transition>

        </div>
    </div>
</template>

<script>
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"

export default {
    mixins: [ dashUtilsMixin ],

    props: {
        value: {
            type: Object | null,
            required: true
        },
        field: {
            type: String,
            required: true
        },
        optionsDropdown: {
            type: Array,
            required: true
        },
        deselectable: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        tooltip: {
            type: String,
            required: false,
            default: ""
        },
        field_id: {
            type: String,
            default: "id"
        },
        field_name: {
            type: String,
            default: "name"
        },
        field_label: {
            type: String
        }
    },

    data() {
        return {
            selectedOption: this.value,
            dropdownExpanded: false,
        }
    },

    computed: {
        // ======================== Subtitle treatment here ========================
        selectStrings() {
            let selectStrings = {}
            const fieldConfig = {
                "Departamento Regional": {
                    label: "Departamento Regional",
                    allOptions: "Todos os Departamentos",
                    class: "dr"
                },
                "Campanha": {
                    label: "Selecionar Campanha",
                    allOptions: "Todas as Campanhas",
                    class: "campaign"
                },
                "Estado": {
                    label: "Selecionar Estado",
                    allOptions: "Todos os Estados",
                    class: "state"
                },
                "UF": {
                    label: "Selecionar UF",
                    allOptions: "Todas as UFs",
                    class: "dr"
                },
                "Grupo": {
                    label: "Selecionar Grupo",
                    allOptions: "Todos os Grupos",
                    class: "group"
                },
                "DR SESI": {
                    label: "Selecionar DR SESI",
                    allOptions: "Todos os DRs",
                    prefix: "SESI/",
                    class: "state"
                },
                "Endereço": {
                    label: "Selecionar Endereço",
                    allOptions: "Todos os Endereços",
                    class: "dr"
                },
                "Empresa": {
                    label: "Selecionar Empresa",
                    allOptions: "Todas as empresas",
                    class: "dr"
                },
                };

                const config = fieldConfig[this.field];
                if (config) {
                selectStrings.label = config.label;
                selectStrings.allOptions = config.allOptions;
                selectStrings.class = config.class;
                selectStrings.prefix = config.prefix || "";
                }

                return selectStrings
        },
        // =========================================================================

        label() {
            return this.selectStrings.label
        },
        className() {
            return this.selectStrings.class
        },
        optionName() {
            let optionName = this.selectStrings.allOptions
            if (this.selectedOption) {
                optionName = this.selectStrings.prefix + this.selectedOption[this.field_name]
            }

            return optionName;
        },

        titleDisplay() {
            let titleDisplay = ""

            if (this.selectedOption) {
                titleDisplay = this.selectedOption[this.field_name]
                if (this.field == "Campanha") titleDisplay += ` - Início em ${this.displayDateFormat(this.selectedOption.startDate)}`

            } else {
                for (const option of this.optionsDropdown) {
                    if (this.field == "Campanha"){
                        titleDisplay += `${option[this.field_name]} - Início em ${this.displayDateFormat(option.startDate)} \n`
                    }
                    else {
                        titleDisplay += `${option[this.field_name]} \n`
                    }
                }
            }

            return titleDisplay
        }
    },

    watch: {
        value() {
            this.selectedOption = this.value
        }
    },

    mounted() {
        document.addEventListener("click", this.closeDropdowns)
    },
    beforeDestroy() {
        document.removeEventListener("click", this.closeDropdowns)
    },

    methods: {
        openDropdown() {
            if (!this.disabled) this.dropdownExpanded = true
        },

        toggleOption(option) {
            if (option != this.selectedOption) this.selectedOption = option
            else if (this.deselectable) this.selectedOption = null

            this.$emit("input", this.selectedOption)
        },

        setTitle(option) {
            let title = this.selectStrings.prefix + option[this.field_name]
            if (this.field == "Campanha") {
                title = `${option[this.field_name]} - Início em ${this.displayDateFormat(option.startDate)}`
            }

            return title
        },

        checkSelected(optionName) {
            return this.selectedOption ? optionName[this.field_id] == this.selectedOption[this.field_id] : false
        },

        closeDropdowns(event) {
            if (!event.target.classList.contains(`${this.className}-display`)) this.dropdownExpanded = false
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/views/custom-pages/dashboard/shared-styles/filter-field.scss";
</style>