<template>
    <div class="dashboard-comparative-data panel">
        <div class="padding-up">
            <div class="d-flex align-items-center">
                <span class="ml-3 mr-1" for="tableSize">Mostrar</span>
                <v-select
                    id="tableSize"
                    v-model="paginationData.defaultSize"
                    @input="updateTableSize($event)"
                    :options="table.optionsSize"
                    :clearable="false"
                    style="width: 100px;"
                />
            </div>
            <div class="align-button">
                <b-button @click="downloadExcel()" variant="custom-blue" class="button-spreadsheet" :disabled="downloadingSheet">
                    <span v-if="downloadingSheet" class="text-white spinner-border spinner-border-white spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    <feather-icon icon="DownloadIcon" class="mr-50" />
                    Baixar planilha
                </b-button>
            </div>
        </div>
        <b-table
          id="tableEmpresa"
          responsive
          :sticky-column="false"
          :busy.sync="sortingComparative"
          :fields="table.fields"
          :items="companyList"
          no-local-sorting
          :no-sort-reset="true"
          @sort-changed="sortColumn"
          @row-clicked="openAdress"
          :tbody-tr-class="showRow"
        >
        <template #cell(nome_razao_social)="row">
            
            <div v-if="row.item.nome" class="d-flex align-items-center ">
                <div v-if="adressSheet" class="mw-50 d-flex align-items-center justify-content-center">
                    <feather-icon
                    :icon="row.item.open ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    size="38"
                    />
                </div>
                <div class="pl-1 comprimento_maximo_celulas">
                    <span class="font-weight-bolder comprimento_maximo_celulas">{{ row.item.cnpj | VMask('##.###.###/####-##') }}</span>
                    <br>
                    <span class="font-weight-normal comprimento_maximo_celulas" :title="row.item.nomeFantasia.length > 45 ? row.item.nomeFantasia : null" v-b-tooltip> 
                        {{ row.item.nomeFantasia }}
                    </span>
                    <br>
                    <span class="font-weight-bold comprimento_maximo_celulas" :title="row.item.nome.length > 45 ? row.item.nome : null" v-b-tooltip> 
                        {{ row.item.nome }}
                    </span>
                </div>
            </div>
            <div v-else class="d-flex align-items-center">
                <div class="mw-50 d-flex align-items-center justify-content-center">
                <feather-icon
                icon="MapPinIcon"
                size="24"
                />
                </div>
                <span class="font-weight-normal pl-1">
                <strong v-if="row.item.endereco.descricao">{{ row.item.endereco.descricao }}<br></strong>
                {{ row.item.endereco.logradouro }}, {{ row.item.endereco.numero }}, {{ row.item.endereco.bairro }}, <br>
                {{ row.item.endereco.cidade }}, {{ row.item.endereco.uf_adesao }}
                </span>
            </div>
          </template>
          <template #cell(andamento_vacinacao)="row">
          {{ parseFloat(row.item.andamento_vacinacao).toFixed(0) }}%
        </template>
        <template #cell(pessoas_cadastradas)="row">
          {{ row.item.pessoas_cadastradas ? row.item.pessoas_cadastradas : '-' }}
        </template>
        <template #cell(pessoas_nao_vacinadas)="row">
          {{ row.item.pessoas_nao_vacinadas ? row.item.pessoas_nao_vacinadas : '-' }}
        </template>
        <template #cell(vacinados_total)="row">
          {{ row.item.vacinados_total ? parseFloat(row.item.vacinados_total).toFixed(0) + '%' : '-' }}
        </template>
        </b-table>
        <div class="pb-2 px-1 ml-2">
            <b-col sm="12" class="mt-2">
                <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
            </b-col>
        </div>
    </div>
</template>
<script>
import {
  BTable, BRow, BCol, BButton
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';

export default {

    props: {
        empresasComparativo: {
            required: true
        },
        adressSheet: {
            type: Boolean,
            default: false
        },
        selectedCampaign: {
          type: Object|Array,
          required: false,
          default: () => []
        },
        sortingComparative: {
            type: Boolean,
            default: false
        },
        downloadingSheet: {
            type: Boolean,
            default: false
        }
    },
    components: {
        BTable,
        BRow,
        BCol,
        CustomPagination,
        vSelect,
        BButton
    },

    data() {
        return {
            moment,
            table: {
                fields: [
                    {
                    key: 'nome_razao_social', label: 'EMPRESA | CNPJ', sortable: true, class: 'mw-400'
                    },
                    {
                    key: 'doses_solicitadas', label: 'Doses Solicitadas', sortable: true,
                    },
                    {
                    key: 'doses_restantes', label: 'Doses Restantes', sortable: true,
                    },
                    {
                    key: 'pessoas_vacinadas', label: 'Pessoas Vacinadas', sortable: true,
                    },
                    {
                    key: 'andamento_vacinacao', label: 'Andamento Vacinação', sortable: true,
                    },
                    {
                    key: 'pessoas_cadastradas', label: 'Pessoas Cadastradas', sortable: true,
                    },
                    {
                    key: 'pessoas_nao_vacinadas', label: 'Pessoas Não Vacinadas', sortable: true,
                    },
                    {
                    key: 'vacinados_total', label: 'Vacinados/Total', sortable: true,
                    }
                ],
                items: [],
                optionsSize: [10, 25, 50, 100],
                columnOrder: 'nome_razao_social',
                order: 'desc',
            },
            filtering: false,
            orderByColumn: "",
            isAscending: true,
            imgAscClass: '',
            imgDescClass: '',
            boolean: true,
            colors: {
                nome: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                doses_solicitadas: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                doses_restantes: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                pessoas_vacinadas: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                andamento_vacinacao: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                pessoas_cadastradas: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                pessoas_nao_vacinadas: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                vacinados_total: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
            }
        };
    },
    computed: {
        companyList() {
            let companies = this.empresasComparativo.dados_paginados.filter(empresa => empresa.nome != null)

            if(this.adressSheet){
                const adresses = this.empresasComparativo.dados_paginados.filter(empresa => empresa.nome == null)
                adresses.map((item) => {
                    let company = companies.findIndex(adress => adress.id == item.id)

                    companies.splice(company + 1, 0, item)
                })
            }
            return companies
        },
        paginationData() {
            return {
               currentPage: this.empresasComparativo.pagina,
               totalLines: this.empresasComparativo.total_linhas,
               fromLine: this.empresasComparativo.da_linha,
               toLine: this.empresasComparativo.ate_linha,
               defaultSize: this.empresasComparativo.limite_por_pagina,
            };
        }
    },
    methods: {
        downloadExcel() {
            const info = {
                sortBy: this.table.columnOrder,
                order: this.table.order,
                currentPage: 1,
                defaultSize: this.paginationData.totalLines,
                selectedCampaign: this.selectedCampaign,
                adressSheet: this.adressSheet
            }
            this.$emit("downloadSheet", info);
        },
        sortColumn(sort) {
            this.table.columnOrder = sort.sortBy
            this.table.order = sort.sortDesc ? 'desc' : 'asc'
            this.emitEventSort();
        },
        updateCurrentPage(page) {
            this.paginationData.currentPage = page;
            this.emitEventSort();
        },
        updateTableSize(event) {
            if(!event) {
                this.paginationData.defaultSize = 10;
            }
    
            this.paginationData.currentPage = 1;
            this.emitEventSort();
        },
        showRow(item) {
            let rowClass = 'font-weight-bold';
            if (!item.visivel){
                rowClass = 'd-none';
            }
            return rowClass;
        },
        emitEventSort() {
            const sort = {
                sortBy: this.table.columnOrder,
                order: this.table.order
            };
            this.$emit("sortData", {...this.paginationData, ...sort});
        },
        openAdress(item) {
            item.open = !item.open;
            
            if(item.nome){
                this.empresasComparativo.dados_paginados.filter(empresa => empresa.id == item.id && !empresa.nome).map(item => item.visivel = !item.visivel)
            }
        },
    },
}
</script>
  
  
<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";

/* CSS RESET */
* {
    margin: 0;
    padding: 0;
    border: 0;
}

.max-td {
    min-width: 30% !important;
}

.align-external-imgs-donw-up {
    width: 100%;
    display: flex;
    justify-content: start;
    justify-items: center;
    align-items: center;
    align-content: center;
    padding-left: responsive-width(10px);
}

.img-asc {
    cursor: pointer;
}


.img-desc {
    cursor: pointer;
}

.align-button {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: responsive-width(22px);
}

.button-spreadsheet {
    width: responsive-width(177px);
    height: responsive-height(37px);
    border-radius: 5px;
    background-color: #2772C0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(14px);
    font-weight: 500;
    line-height: responsive-height(17px);
    letter-spacing: responsive-height(0.4000000059604645px);
    color: #FFFFFF;
    &:hover {
            background-color: #1a508a;
        }
}

.padding-up {
    height: responsive-height(77px);
    display: flex;
    align-items: center;
}

.align-imgs-down-up {
    display: flex;
    flex-direction: column;
}

.table-container tr {
    font-size: mobile-responsive-height(12px) !important;
    padding-top: responsive-height(20px);
    border-bottom: 1px solid #ddd;
}

.table-container {
    width: 100%;
}

.table-container svg {
    cursor: pointer;
}

.cnpj {
    display: block;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
}

.span-tr {
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.table-container tr th {
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(12px);
    font-weight: 600;
    line-height: responsive-height(15px);
    letter-spacing: 0px;
    text-align: left;
    height: responsive-height(40px);
    color: #5E5873;
    padding-left: responsive-width(16px);
    background-color: #EFEFEF !important;

}

.table-container td {
    padding-left: responsive-width(16px);
    padding-top: responsive-height(14px);
    padding-bottom: responsive-height(14px);
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(14px);
    font-weight: 600;
    line-height: responsive-height(21px);
    letter-spacing: 0px;
    text-align: left;

}

.table-container td div {
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(12px);
    font-weight: 400;
    line-height: responsive-height(15px);
    letter-spacing: 0px;
    text-align: left;
    color: #6E6B7B;
}

.table-container tr:first-child {
    border-top: none;
}

.dashboard-comparative-data {
    padding: 0;
    min-height: 450px;
}

.start-icon {
    width: responsive-width(14px);
    height: responsive-width(14px);
    margin-right: responsive-width(8px);
}

.comprimento_maximo_celulas{
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}

@media (max-width: 655px) { 
    .comprimento_maximo_celulas{
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
    }
}

@media (max-width: 400px) { 
    .comprimento_maximo_celulas{
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
    }
}
</style>
  