<template>
    <div class="dashboard-filter panel">
        <transition-group name="notification">

            <FilterNotification 
                :key="'notification'"
                v-if="notificationExists"
                :notificationType="notificationType" 
                v-model="showNotification"/>

            <div :key="'filter-fields'">
                <b-row>
                  <b-col lg="2" class="mb-2">
                    <SingleSelectFilter
                        :field = "'Departamento Regional'"
                        :optionsDropdown = "drDropdown"
                        :deselectable = true
                        v-model = "selectedDR"/>
                  </b-col>
                  <b-col lg="3" class="mb-2">
                    <MultipleSelectFilter
                        :field = "'Unidade Operacional'"
                        :optionsDropdown = "uoDropdown"
                        v-model = "selectedUO"/>
                  </b-col>
                  <b-col lg="3" class="mb-2">
                    <MultipleSelectFilter
                        :field = "'Campanha'"
                        :optionsDropdown = "campaignsDropdown"
                        v-model = "selectedCampaign"/>
                  </b-col>
                  <b-col lg="2" class="mb-2">
                    <DateFilter
                        inputDateType = "start-date"
                        v-model = "selectedStartDate" />
                  </b-col>
                  <b-col lg="2" class="mb-2">
                    <DateFilter
                        inputDateType = "end-date"
                        v-model = "selectedEndDate" />
                  </b-col>
                </b-row>
                <b-row v-if="nacional">
                  <b-col lg="3" class="mb-2">
                    <MultipleSelectFilter ref="MultipleSelectFilterGrupo"
                      :field = "'Grupo'"
                      :optionsDropdown = "grupoDropdown"
                      v-model = "selectedGrupo"
                      @input = "getNegociosPorGrupos()"
                    />
                  </b-col>
                  <b-col lg="3" class="mb-2">
                    <MultipleSelectFilter ref="MultipleSelectFilterNegocio"
                      :field = "'Todos os Negócios'"
                      :optionsDropdown = "negociosDropdown"
                      :disabled = disabledNegocio
                      v-model = "selectedNegocio"
                      @input = "getEmpresasPorNegociosEGrupos()"
                    />
                  </b-col>
                  <b-col lg="4" class="mb-2">
                    <SelectFilterEmpresa ref="selectFilterEmpresa"
                     :options="empresasDropdown"
                     :disabled = disabledEmpresa
                     v-model="selectedEmpresa"
                     :multiple="true"
                    />
                  </b-col>
                </b-row>
            </div>

            <FilterTags :allTags = "activeFilterTags" :key="'tags'"/>
          <div class="filter-buttons" :key="'buttons'">
            <b-row>
              <b-col class="mb-2">
                  <button class="btn custom-blue mr-1" @click="filterData()">Filtrar</button>
                  <button class="btn custom-outline-blue" @click="resetFilters()">Limpar</button>
              </b-col>
            </b-row>
          </div>

        </transition-group>
    </div>
</template>

<script>
import moment from 'moment';
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"
import SingleSelectFilter from "@/views/custom-pages/dashboard/shared-components/SingleSelectFilter.vue"
import MultipleSelectFilter from "@/views/custom-pages/dashboard/shared-components/MultipleSelectFilter.vue"
import DateFilter from "@/views/custom-pages/dashboard/shared-components/DateFilter.vue"
import FilterTags from "@/views/custom-pages/dashboard/shared-components/FilterTags.vue"
import FilterNotification from '@/views/custom-pages/dashboard/shared-components/FilterNotification.vue';
import SelectFilterEmpresa from "@/views/custom-pages/dashboard/shared-components/SelectFilterEmpresa.vue";
import {BCol, BRow} from "bootstrap-vue";
export default {
    components: {
      BRow, BCol, SelectFilterEmpresa,
        SingleSelectFilter,
        MultipleSelectFilter,
        DateFilter,
        FilterTags,
        FilterNotification
    },

    mixins: [ dashUtilsMixin ],

    props: {
        filterFields: {
            type: Object,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            selectedDR: null,
            selectedUO: [],
            selectedCampaign: [],
            selectedStartDate: `01/01/${moment().year()}`,
            selectedEndDate: moment().format('DD/MM/YYYY'),
            filterSelectedOptions: this.value,
            currentFilteredParams: this.value,
            uoToFilter: this.filterFields.userUO,
            afterFirstFilter: false,
            showNotification: true,
            notificationType: 1,       // 1 - Filter Changes      2 - Invalid Data
            activeFilterTags: [],
            selectedGrupo: [],
            selectedEmpresa: [],
            selectedNegocio: [],
            negociosDropdown: [],
            empresasDropdown: [],
            disabledNegocio: true,
            disabledEmpresa: true,
        }
    },

    computed: {
        nacional() {
          return !this.campaignsToFilter.some(campaign => !campaign.nacional)
        },
        drDropdown() {
            let drList = this.filterFields.userDR
            this.orderAlphabetically(drList)
            return drList
        },

        uoDropdown() {
            let uoList = []

            if (!this.selectedDR) {
                uoList = this.filterFields.userUO
            } else {
                let uoFromSelectedDR = this.selectedDR.UOinDR

                for (const uo of this.filterFields.userUO) {
                    if (uoFromSelectedDR.includes(uo.id)) uoList.push(uo)
                }

                // Updating the selected UO Array to include only UO from the currently selected DR.
                this.selectedUO = this.selectedUO.filter(
                    uo => uoList.some(selected => uo.id == selected.id)
                )
            }

            this.orderAlphabetically(uoList)
            return uoList
        },

        grupoDropdown() {
          let grupoList = this.filterFields.userGrupos
          this.orderAlphabetically(grupoList)

          return grupoList
        },

        campaignsDropdown() {
            let campaignsList = []

            if (!this.selectedDR) {
                campaignsList = this.filterFields.userCampaigns
            } else {
                let campaignsFromSelectedDR = this.selectedDR.campaignsInDR

                for (const campaign of this.filterFields.userCampaigns) {
                    if (campaignsFromSelectedDR.includes(campaign.id)) campaignsList.push(campaign)
                }

                if (this.selectedCampaign && this.selectedCampaign.some(campaignId => !campaignsFromSelectedDR.includes(campaignId))) {
                  this.selectedCampaign = [];
                }

            }

            this.orderAlphabetically(campaignsList)
            this.orderByRecentDate(campaignsList)
            return campaignsList
        },

        // If no DR was selected, the entire list is filtered. Otherwise, only the selected DR is filtered.
        drToFilter() {
            if (this.selectedDR) return [this.selectedDR]
            else return [...this.drDropdown].sort((a, b) => a.id - b.id)
        },

        campaignsToFilter() {
          if (this.selectedCampaign.length == 0) return [...this.campaignsDropdown].sort((a, b) => a.id - b.id)
          else return [...this.selectedCampaign].sort((a, b) => a.id - b.id)
        },

        gruposToFilter() {
          if (this.selectedGrupo.length == 0 && this.nacional) return [...this.grupoDropdown].sort((a, b) => a.id - b.id)
          else return [...this.selectedGrupo].sort((a, b) => a.id - b.id)
        },
        empresasToFilter() {
          if (this.selectedEmpresa.length == 0) return [...this.empresasDropdown].sort((a, b) => a.id - b.id)
          else return [...this.selectedEmpresa].sort((a, b) => a.id - b.id)
        },

        negociosToFilter() {
          if (this.selectedNegocio.length == 0) return [...this.negociosDropdown].sort((a, b) => a.id - b.id)
          else return [...this.selectedNegocio].sort((a, b) => a.id - b.id)
        },

        notificationExists() {
            if (this.notificationType == 1) return this.filterChanges && this.showNotification && this.afterFirstFilter
            else return this.filterChanges && this.showNotification
        },

        filterChanges() {
            this.filterSelectedOptions = {
                dr: this.drToFilter.map(dr => dr.id),
                uo: this.uoToFilter.map(uo => uo.id),
                campaigns: this.campaignsToFilter.map(campaign => campaign.id),
                grupos: this.nacional ?(this.gruposToFilter.map(grupo => grupo.id)) : [],
                startDate: this.selectedStartDate,
                endDate: this.selectedEndDate
            }

            if(this.nacional){
              if (this.negociosToFilter.length > 0) {
                this.filterSelectedOptions.negocios = this.negociosToFilter.map(negocio => negocio.id);
              }
              if (this.empresasToFilter.length > 0) {
                this.filterSelectedOptions.empresas = this.empresasToFilter.map(empresa => empresa.id_empresa);
              }
            }

            const currentDataFilterContent = JSON.stringify(this.currentFilteredParams)
            const selectedOptionsContent = JSON.stringify(this.filterSelectedOptions)
            
            this.notificationType = 1
            this.showNotification = true

            return selectedOptionsContent !== currentDataFilterContent
        }
    },

    watch: { 
        value() {
            this.currentFilteredParams = this.value
        },

        // If 0 Operational Units were selected, the entire list is filtered. Otherwise, only the selected Operational Units are filtered.
        selectedUO() {
            if (this.selectedUO.length == 0) this.uoToFilter = [...this.uoDropdown]
            else this.uoToFilter = [...this.selectedUO]
            this.uoToFilter.sort((a, b) => a.id - b.id)
        }
    },

    methods: {
        generateActiveFilterTags() {
          this.activeFilterTags = []
          let tagText = ""
          let tagTitle = ""

          if (this.selectedDR != null) tagText = tagTitle = this.selectedDR.name
          else tagText = tagTitle = "Todos os Departamentos"
          this.activeFilterTags.push({ text: tagText, title: tagTitle })

          let uoTags = [...this.uoToFilter]
          this.orderAlphabetically(uoTags)
          let campaignsTags = [...this.campaignsToFilter]
          this.orderAlphabetically(campaignsTags)
          let empresasTags = [...this.empresasToFilter]
          this.orderAlphabetically(empresasTags)
          let gruposTags = [...this.gruposToFilter]
          this.orderAlphabetically(gruposTags)
          let negociosTags = [...this.negociosToFilter]
          this.orderAlphabetically(negociosTags)

          // Less than 10 UO = 1 tag for each.
          if (uoTags.length <= 10) {
            for (const uo of uoTags) {
              tagText = tagTitle = uo.name
              this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }

            // More than 10 UO = 1 tag for All UO.
          } else {
            tagText = `${uoTags.length} Unidades`
            tagTitle = ""

            for (const uo of uoTags) {
              tagTitle += `${uo.name}\n`
            }

            this.activeFilterTags.push({ text: tagText, title: tagTitle })
          }

          if (campaignsTags.length <= 1) {
            for (const campanha of campaignsTags) {
              tagText = tagTitle = campanha.name
              this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }
          } else {
            tagText = `${campaignsTags.length} Campanhas`

            for (const campanha of campaignsTags) {
              tagTitle += `${campanha.name}\n`
            }

            this.activeFilterTags.push({ text: tagText, title: tagTitle })
          }

          if (empresasTags.length <= 1) {
            for (const empresa of empresasTags) {
              tagText = tagTitle = empresa.nome_empresa
              this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }
          } else {
            tagText = `${empresasTags.length} Empresas`

            for (const empresa of empresasTags) {
              tagTitle += `${empresa.nome_empresa}\n`
            }

            this.activeFilterTags.push({ text: tagText, title: tagTitle })
          }

          if (gruposTags.length <= 1) {
            for (const grupo of gruposTags) {
              tagText = tagTitle = grupo.name
              this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }
          } else {
            tagText = `${gruposTags.length} Grupos`

            for (const grupo of gruposTags) {
              tagTitle += `${grupo.name}\n`
            }

            this.activeFilterTags.push({ text: tagText, title: tagTitle })
          }
          if (negociosTags.length <= 1) {
            for (const negocio of negociosTags) {
              tagText = tagTitle = negocio.name
              this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }
          } else {
            tagText = `${negociosTags.length} Negócios`

            for (const negocio of negociosTags) {
              tagTitle += `${negocio.name}\n`
            }

            this.activeFilterTags.push({ text: tagText, title: tagTitle })
          }

          tagText = tagTitle = `${this.selectedStartDate} até ${this.selectedEndDate}`
          this.activeFilterTags.push({ text: tagText, title: tagTitle })
        },
        filterData() {
            if (!this.validateDate(this.selectedStartDate) || !this.validateDate(this.selectedEndDate)) {
                this.notificationType = 2
                this.showNotification = true
                return
            } else if (this.startGreaterThanEndDate(this.selectedStartDate, this.selectedEndDate)) {
                this.notificationType = 3
                this.showNotification = true
                return
            }

            this.currentFilteredParams = this.filterSelectedOptions
            this.afterFirstFilter = true

            this.$emit("input", this.currentFilteredParams)
            this.$emit("filterUpdade", this.currentFilteredParams)
        },

        resetFilters() {
            this.selectedDR = null
            this.selectedUO = []
            this.selectedCampaign = []
            this.selectedStartDate = `01/01/${moment().year()}`
            this.selectedEndDate = moment().format('DD/MM/YYYY')
            this.$refs.selectFilterEmpresa.selectDefaultItem();
            this.disabledEmpresa = true;
            this.empresasDropdown = []
            this.selectedNegocio = []
            this.negociosDropdown = []
            this.disabledNegocio = true;
            this.selectedGrupo = []
        },

        async getNegociosPorGrupos() {
          this.selectedNegocio = [];
          this.negociosDropdown = [];
          this.$refs.selectFilterEmpresa.selectDefaultItem();
          this.selectedEmpresa = [];
          this.empresasDropdown = [];


          const allBusinessOption = this.$refs.MultipleSelectFilterNegocio.$el.querySelector("#optionsAllBusiness")
          allBusinessOption.classList.add("filter-display-disabled")
          this.disabledNegocio = true
          this.disabledEmpresa = true;

          if(this.selectedGrupo.length > 0){
            const selectedGroupIds = this.selectedGrupo.map(group => group.id)

            const params = {
              idGrupo: selectedGroupIds,
            }

            await this.$http.get(this.$api.grupoNegocio(), { params })
                .then(response => {
                  if (response.status === 200) {
                    this.negociosDropdown = response.data.map(negocio => ({
                      id: negocio.id_grupo_negocio,
                      name: negocio.descricao,
                    }));

                    allBusinessOption.classList.remove("filter-display-disabled")
                    this.disabledNegocio = false
                  }
                });
          }
        },

        async getEmpresasPorNegociosEGrupos() {
          this.$refs.selectFilterEmpresa.selectDefaultItem();
          this.selectedEmpresa = [];
          this.empresasDropdown = [];
          this.disabledEmpresa = true;

          if(this.selectedNegocio.length > 0){
            const selectedGroupIds = this.selectedGrupo.map(group => group.id)
            const selectedNegociosIds = this.selectedNegocio.map(negocio => negocio.id)

            const params = {
              idsGrupos: selectedGroupIds,
              negocio: selectedNegociosIds,
            }

            await this.$http.get(this.$api.empresasGrupoNegocio(), { params })
                .then(response => {
                  if (response.status === 200) {
                    this.empresasDropdown = response.data.map(empresa => ({
                      id_empresa: empresa.id_empresa,
                      documento: empresa.documento,
                      documento_formatado: this.formatDocument(empresa.documento),
                      nome_empresa: empresa.nome_empresa,
                      razao_social: empresa.razao_social,
                      doc_filter: this.formatDocument(empresa.documento) + ' ' + empresa.documento
                    }));
                  }
                });

            this.disabledEmpresa = false;
          }
        },
        formatDocument(document) {
          if (document.length === 14) {
            return this.$options.filters.VMask(document, '##.###.###/####-##')
          }
          if (document.length === 11) {
            return this.$options.filters.VMask(document, '###.###.###-##')
          }
          return document
        },
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.dashboard-filter {
    position: relative;
	margin-bottom: responsive-height(25px);
}

.filter-selection {
    width: 100%;
}

.filter-buttons {
    width: 100%;
    justify-content: center;

    .filter-btn { width: 100px; }
    .reset-btn { width: 100px; }
}

.active-filters-display {
    margin-bottom: responsive-height(18px);
}

/* Notification Animation */
.notification-enter, .notification-leave-to {
    opacity: 0; 
}

.notification-move, .notification-enter-active {
    transition: all .3s;
}

.notification-leave-active {
    position: absolute;
    width: calc(100% - 4.6vh);
    transition: all .3s;
}
</style>