<template>
  <div class="filter">
    <label for="select" class="font_size_label">{{ custom_label ? custom_label : label }}</label>
    <div
      class="filter-field"
      :class="[`${className}-filter`, {'dropdown-open': dropdownExpanded}]"
      @click="openDropdown()"
    >

    <div
      :id="idName ? idName : null"
      :class="['filter-display', { 'filter-display-disabled': disabled }]"
    >
      <input
          class="options-display"
          :class="`${className}-display`"
          :value="optionsDisplay.text"
          :title="optionsDisplay.title"
          readonly
        >
    </div>

      <transition name="fade">
        <ul
          v-show="dropdownExpanded"
          class="filter-dropdown"
          :class="{'show': dropdownExpanded}"
        >

          <li
            v-for="option in optionsDropdown"
            :key="option[field_id]"
            class="dropdown-option"
            :class="[
              `${className}-dropdown`,
              {'selected': checkSelected(option[field_id])}
            ]"
            :title="option[field_name]"
            @click="toggleOption(option)"
          >
            {{ option[field_name] }}
          </li>

        </ul>
      </transition>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    field: {
      type: String,
      required: true,
    },
    optionsDropdown: {
      type: Array,
      default: () => ([]),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    field_id: {
      type: String,
      default: 'id',
    },
    field_name: {
      type: String,
      default: 'name',
    },
    custom_label: {
      type: String
    }
  },

  data() {
    return {
      selectedOptions: this.value,
      dropdownExpanded: false,
    }
  },

  computed: {
    // ======================== Subtitle treatment here ========================
    selectStrings() {
      const selectStrings = {}

      if (this.field == 'Unidade Operacional') {
        selectStrings.label = 'Unidade Operacional'
        selectStrings.allOptions = 'Todas as Unidades'
        selectStrings.selectedOptions = 'Unidades Selecionadas'
        selectStrings.class = 'uo'
      } else if (this.field == 'Empresa') {
        selectStrings.label = 'Selecionar Empresa'
        selectStrings.allOptions = 'Todas as Empresas'
        selectStrings.selectedOptions = 'Empresas Selecionadas'
        selectStrings.class = 'company'
        selectStrings.id = 'optionsAllCompany'
      } else if (this.field == 'Negócio') {
        selectStrings.label = 'Selecionar Negócio vinculado'
        selectStrings.allOptions = 'Negócio vinculado'
        selectStrings.selectedOptions = 'Negócios Selecionados'
        selectStrings.class = 'business'
      } else if (this.field == 'Grupo') {
        selectStrings.label = 'Selecionar Grupo'
        selectStrings.allOptions = 'Todos os Grupos'
        selectStrings.selectedOptions = 'Grupos Selecionados'
        selectStrings.class = 'groups'
      } else if (this.field == 'Todos os Negócios') {
        selectStrings.label = 'Selecionar Negócios'
        selectStrings.allOptions = 'Todos os Negócios'
        selectStrings.selectedOptions = 'Negócios Selecionados'
        selectStrings.class = 'business'
        selectStrings.id = 'optionsAllBusiness'
      } else if (this.field == 'Campanha') {
        selectStrings.label = 'Selecionar Campanhas'
        selectStrings.allOptions = 'Todas as Campanhas'
        selectStrings.selectedOptions = 'Campanhas Selecionadas'
        selectStrings.class = 'campaigns'
      }

      return selectStrings
    },
    // =========================================================================

    label() {
      return this.selectStrings.label
    },
    className() {
      return this.selectStrings.class
    },
    idName() {
      return this.selectStrings.id
    },
    displayTextAll() {
      return this.selectStrings.allOptions
    },
    displayTextSelected() {
      return this.selectStrings.selectedOptions
    },

    optionsDisplay() {
      const optionsDisplay = {}

      if (this.selectedOptions.length == 0) {
        optionsDisplay.text = this.displayTextAll
        optionsDisplay.title = ''

        for (const option of this.optionsDropdown) {
          optionsDisplay.title += `${option[this.field_name]} \n`
        }
      } else if (this.selectedOptions.length == 1) {
        optionsDisplay.text = this.selectedOptions[0][this.field_name]
        optionsDisplay.title = this.selectedOptions[0][this.field_name]
      } else {
        optionsDisplay.text = `${this.selectedOptions.length} ${this.displayTextSelected}`
        optionsDisplay.title = ''

        for (const option of this.selectedOptions) {
          optionsDisplay.title += `${option[this.field_name]} \n`
        }
      }

      return optionsDisplay
    },
  },

  watch: {
    value() {
      this.selectedOptions = this.value
    },
  },

  mounted() {
    document.addEventListener('click', this.closeDropdowns)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdowns)
  },

  methods: {
    openDropdown() {
      if (!this.disabled) this.dropdownExpanded = true
    },

    toggleOption(clickedOption) {
      const index = this.selectedOptions.findIndex(option => option[this.field_id] == clickedOption[this.field_id])

      if (index == -1) {
        this.selectedOptions.push(clickedOption)
      } else {
        this.selectedOptions.splice(index, 1)
      }

      this.$emit('input', this.selectedOptions)
    },

    checkSelected(optionId) {
      for (const option of this.selectedOptions) {
        if (option[this.field_id] == optionId) return true
      }
      return false
    },

    closeDropdowns(event) {
      const classList = [`${this.className}-filter`, `${this.className}-dropdown`, 'filter-display', `${this.className}-display`]
      let validTarget = false

      for (const c of classList) {
        if (event.target.classList.contains(c)) validTarget = true
      }

      if (!validTarget) this.dropdownExpanded = false
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";
@import "@/views/custom-pages/dashboard/shared-styles/filter-field.scss";

.filter-display-disabled {
  background-color: #F8F8F8 !important;
  .options-display {
    background-color: #F8F8F8 !important;
    color: #5E5873 !important;
  }
}

.filter-display {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    padding: 6px;

    .options-display {
        width: 100%;
        height: 100%;
        background-color: $custom-blue;
        color: #fff;
        border-radius: 4px;
        border: none;
        outline: none;
        cursor: pointer;
        padding-left: responsive-width(10px);
        padding-right: responsive-width(10px);
        display: flex;
        align-items: center;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
