import axiosIns from '@/libs/axios'
import rotasAPI from '@/router/rotasAPI'
import moment from "moment/moment";

export const standardDateFormat = (displayDate) => {
    const dateParts = displayDate.split('/');
    const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    return moment(date).format('YYYY-MM-DD');
};

// Dashboard Admin Empresa - Filter Fields
export const getDashboardFilterOptions = (params) => new Promise((resolve, reject) => {
    params = {
        id_grupo: params.id_grupo,
        ids_campanhas: params.ids_campanhas,
        id_empresa: params.id_empresa
    };

    axiosIns
        .get(rotasAPI.grupoOperadorUf(), { params })
        .then(response => resolve(response))
        .catch(error => reject(error));
});

export const getDashboardData = (props) => new Promise((resolve, reject) => {

    const params = setParams(props);

    axiosIns
        .post(rotasAPI.getDashboardDataAdminEmpresa(), params)
        .then(response => resolve(response))
        .catch(error => reject(error));
});

export const getDashboardDataComparativo = (props) => new Promise((resolve, reject) => {

    const params = setParams(props);

    axiosIns
        .post(rotasAPI.getDashboardDataAdminEmpresaComparativo(), params)
        .then(response => resolve(response))
        .catch(error => reject(error));
});

export function setParams(props) {
    return {
        uf: props.uf,
        idDepartamento: props.id_operador,
        colunaOrdenar: props.sortBy,
        ordem: props.sortDesc ? 'desc' : 'asc',
        pagina: props.currentPage,
        tamanho: props.defaultSize,
        ids_campanhas: props.campaign,
        ids_empresas: props.companies,
        enderecos: props.adresses
    };
}

export const getCompaniesData = (params) => new Promise((resolve, reject) => {
    axiosIns
        .get(rotasAPI.empresaCampanha(), { params })
        .then(response => resolve(response))
        .catch(error => reject(error));
});

