<template>
    <div class="dashboard-filter panel">
        <transition-group name="notification">

            <FilterNotification
                :key="'notification'"
                v-if="this.filterChanges && this.showNotification"
                :notificationType="notificationType"
                v-model="showNotification"/>

            <div :key="'filter-fields'">
                <b-row>
                    <b-col lg="3" class="mb-2">
                        <MultipleSelectFilter
                            v-if="!perfilAdminUnidade"
                            :field = "'Unidade Operacional'"
                            :optionsDropdown = "uoDropdown"
                            v-model = "selectedUO"/>
                    </b-col>
                    <b-col lg="3" class="mb-2">
                        <MultipleSelectFilter
                            :field = "'Campanha'"
                            :optionsDropdown = "campaignsDropdown"
                            v-model = "selectedCampaign"/>
                    </b-col>
                    <b-col lg="2" class="mb-2">
                        <DateFilter
                            inputDateType = "start-date"
                            v-model = "selectedStartDate"/>
                    </b-col>
                    <b-col lg="2" class="mb-2">
                        <DateFilter
                            inputDateType = "end-date"
                            v-model = "selectedEndDate"/>
                    </b-col>
                </b-row>
                <b-row v-if="nacional">
                    <b-col lg="3" class="mb-2">
                        <MultipleSelectFilter ref="MultipleSelectFilterGrupo"
                            :field = "'Grupo'"
                            :optionsDropdown = "grupoDropdown"
                            v-model = "selectedGrupo"
                            @input = "getNegociosPorGrupos()"
                        />
                    </b-col>
                    <b-col lg="3" class="mb-2">
                        <MultipleSelectFilter ref="MultipleSelectFilterNegocio"
                            :field = "'Todos os Negócios'"
                            :optionsDropdown = "negociosDropdown"
                            :disabled = disabledNegocio
                            v-model = "selectedNegocio"
                            @input = "getEmpresasPorNegociosEGrupos()"
                        />
                    </b-col>
                    <b-col lg="4" class="mb-2">
                        <SelectFilterEmpresa ref="selectFilterEmpresa"
                           :options="empresasDropdown"
                           :disabled = disabledEmpresa
                           v-model="selectedEmpresa"
                           :multiple="true"
                        />
                    </b-col>
                </b-row>
                
                <b-row>
                    <b-col class="mb-2">
                        <button class="btn custom-blue mr-1" @click="filterData()">Filtrar</button>
                        <button class="btn custom-outline-blue" @click="resetFilters()">Limpar</button>
                    </b-col>
                </b-row>
            </div>
    
            <FilterTags :allTags = "activeFilterTags" :key="'tags'"/>

        </transition-group>
    </div>
</template>

<script>
import moment from 'moment';
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"
import SingleSelectFilter from "@/views/custom-pages/dashboard/shared-components/SingleSelectFilter.vue"
import MultipleSelectFilter from "@/views/custom-pages/dashboard/shared-components/MultipleSelectFilter.vue"
import DateFilter from "@/views/custom-pages/dashboard/shared-components/DateFilter.vue"
import FilterTags from "@/views/custom-pages/dashboard/shared-components/FilterTags.vue"
import FilterNotification from '@/views/custom-pages/dashboard/shared-components/FilterNotification.vue';
import SelectFilterEmpresa from "@/views/custom-pages/dashboard/shared-components/SelectFilterEmpresa.vue"
import {
  BCol,
  BRow,
  BAlert,
} from 'bootstrap-vue'

export default {
    components: {
        SingleSelectFilter,
        MultipleSelectFilter,
        DateFilter,
        FilterTags,
        FilterNotification,
        BAlert,
        BCol,
        BRow,
        SelectFilterEmpresa
    },

    mixins: [ dashUtilsMixin ],

    props: {
        filterFields: {
            type: Object,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            perfilAdminUnidade: false,
            selectedUO: [],
            selectedGrupo: [],
            selectedEmpresa: [],
            selectedNegocio: [],
            selectedCampaign: [],
            selectedStartDate: `01/01/${moment().year()}`,
            selectedEndDate: moment().format('DD/MM/YYYY'),
            filterSelectedOptions: this.value,
            currentFilteredParams: this.value,
            afterFirstFilter: false,
            showNotification: true,
            notificationType: 1,       // 1 - Filter Changes      2 - Invalid Data
            activeFilterTags: [],
            negociosDropdown: [],
            empresasDropdown: [],
            disabledNegocio: true,
            disabledEmpresa: true,
        }
    },

    computed: {
        nacional() {
          return !this.campaignsToFilter.some(campaign => !campaign.nacional)
        },
        selectedDR() {
            return this.filterFields.userDR[0]
        },
        uoDropdown() {
            let uoList = this.filterFields.userUO
            this.orderAlphabetically(uoList)

            return uoList
        },
        grupoDropdown() {
            let grupoList = this.filterFields.userGrupos
            this.orderAlphabetically(grupoList)

            return grupoList
        },

        campaignsDropdown() {
            let campaignsList = this.filterFields.userCampaigns
            this.orderAlphabetically(campaignsList)
            this.orderByRecentDate(campaignsList)

            return campaignsList
        },

        // If 0 Operational Units were selected, the entire list is filtered. Otherwise, only the selected Operational Units are filtered.
        uoToFilter() {
            if (this.selectedUO.length == 0) return [...this.uoDropdown].sort((a, b) => a.id - b.id)
            else return [...this.selectedUO].sort((a, b) => a.id - b.id)
        },

        // If no campaign was selected, the entire list is filtered. Otherwise, only the selected campaign is filtered.
        campaignsToFilter() {
            if (this.selectedCampaign.length == 0) return [...this.campaignsDropdown].sort((a, b) => a.id - b.id)
            else return [...this.selectedCampaign].sort((a, b) => a.id - b.id)
        },

        empresasToFilter() {
            if (this.selectedEmpresa.length == 0) return [...this.empresasDropdown].sort((a, b) => a.id - b.id)
            else return [...this.selectedEmpresa].sort((a, b) => a.id - b.id)
        },

        gruposToFilter() {
            if (this.selectedGrupo.length == 0 && this.nacional) return [...this.grupoDropdown].sort((a, b) => a.id - b.id)
            else return [...this.selectedGrupo].sort((a, b) => a.id - b.id)
        },

        negociosToFilter() {
            if (this.selectedNegocio.length == 0) return [...this.negociosDropdown].sort((a, b) => a.id - b.id)
            else return [...this.selectedNegocio].sort((a, b) => a.id - b.id)
        },

        filterChanges() {
            this.filterSelectedOptions = {
                dr: [this.selectedDR.id],
                uo: this.uoToFilter.map(uo => uo.id),
                campaigns: this.campaignsToFilter.map(campaign => campaign.id),
                grupos: (this.gruposToFilter.map(grupo => grupo.id)),
                startDate: this.selectedStartDate,
                endDate: this.selectedEndDate
            }

            if(this.nacional){
              if (this.negociosToFilter.length > 0) {
                  this.filterSelectedOptions.negocios = this.negociosToFilter.map(negocio => negocio.id);
              }
              if (this.empresasToFilter.length > 0) {
                  this.filterSelectedOptions.empresas = this.empresasToFilter.map(empresa => empresa.id_empresa);
              }
            }

            const currentDataFilterContent = JSON.stringify(this.currentFilteredParams)
            const selectedOptionsContent = JSON.stringify(this.filterSelectedOptions)

            this.notificationType = 1
            this.showNotification = true
            
            return selectedOptionsContent !== currentDataFilterContent
        }
    },

    watch: { 
        value() {
            this.currentFilteredParams = this.value
        }
    },

    created() {
        const userData = this.$store.getters['sessions/getUserData']
        this.perfilAdminUnidade = userData.perfis[0].chave == "ADMIN_UNIDADE"

        if (this.perfilAdminUnidade) this.selectedUO = this.filterFields.userUO
    },

    beforeMount() {
        this.generateActiveFilterTags()
    },

    methods: {
        filterData() {
            if (!this.validateDate(this.selectedStartDate) || !this.validateDate(this.selectedEndDate)) {
                this.notificationType = 2
                this.showNotification = true
                return
            } else if (this.startGreaterThanEndDate(this.selectedStartDate, this.selectedEndDate)) {
                this.notificationType = 3
                this.showNotification = true
                return
            }

            this.currentFilteredParams = this.filterSelectedOptions
            this.afterFirstFilter = true

            this.$emit("input", this.currentFilteredParams)
            this.$emit("filterUpdade", this.currentFilteredParams)
        },

        resetFilters() {
            this.selectedUO = this.perfilAdminUnidade ? this.filterFields.userUO : []
            this.selectedCampaign = []
            this.selectedStartDate = `01/01/${moment().year()}`
            this.selectedEndDate = moment().format('DD/MM/YYYY')
            this.disabledEmpresa = true;
            this.empresasDropdown = []
            this.selectedNegocio = []
            this.negociosDropdown = []
            this.disabledNegocio = true;
            this.selectedGrupo = []

            if (this.$refs.selectFilterEmpresa) {
                this.$refs.selectFilterEmpresa.selectDefaultItem();
            }
        },

        generateActiveFilterTags() {
            this.activeFilterTags = []
            let tagText = ""
            let tagTitle = ""
            
            let uoTags = [...this.uoToFilter]
            this.orderAlphabetically(uoTags)
            let campaignsTags = [...this.campaignsToFilter]
            this.orderAlphabetically(campaignsTags)
            let empresasTags = [...this.empresasToFilter]
            this.orderAlphabetically(empresasTags)
            let gruposTags = [...this.gruposToFilter]
            this.orderAlphabetically(gruposTags)
            let negociosTags = [...this.negociosToFilter]
            this.orderAlphabetically(negociosTags)

            // Less than 10 Companies = 1 tag for each.
            if (uoTags.length <= 10) {
                for (const uo of uoTags) {
                    tagText = tagTitle = uo.name
                    this.activeFilterTags.push({ text: tagText, title: tagTitle })
                }
            
            // More than 10 UO = 1 tag for All UO.
            } else {
                tagText = `${uoTags.length} Unidades`

                for (const uo of uoTags) {
                    tagTitle += `${uo.name}\n`
                }

                this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }
            
            if (campaignsTags.length <= 1) {
                for (const campanha of campaignsTags) {
                  tagText = tagTitle = campanha.name
                  this.activeFilterTags.push({ text: tagText, title: tagTitle })
                }
            } else {
                tagText = `${campaignsTags.length} Campanhas`

                for (const campanha of campaignsTags) {
                  tagTitle += `${campanha.name}\n`
                }

                this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }

            if (empresasTags.length <= 1) {
                for (const empresa of empresasTags) {
                  tagText = tagTitle = empresa.nome_empresa
                  this.activeFilterTags.push({ text: tagText, title: tagTitle })
                }
            } else {
                tagText = `${empresasTags.length} Empresas`

                for (const empresa of empresasTags) {
                  tagTitle += `${empresa.nome_empresa}\n`
                }

                this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }

            if (gruposTags.length <= 1) {
                for (const grupo of gruposTags) {
                  tagText = tagTitle = grupo.name
                  this.activeFilterTags.push({ text: tagText, title: tagTitle })
                }
            } else {
                tagText = `${gruposTags.length} Grupos`

                for (const grupo of gruposTags) {
                  tagTitle += `${grupo.name}\n`
                }

                this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }
            if (negociosTags.length <= 1) {
                for (const negocio of negociosTags) {
                  tagText = tagTitle = negocio.name
                  this.activeFilterTags.push({ text: tagText, title: tagTitle })
                }
            } else {
                tagText = `${negociosTags.length} Negócios`

                for (const negocio of negociosTags) {
                  tagTitle += `${negocio.name}\n`
                }

                this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }

            tagText = tagTitle = `${this.selectedStartDate} até ${this.selectedEndDate}`
            this.activeFilterTags.push({ text: tagText, title: tagTitle })
        },

        async getEmpresasPorNegociosEGrupos() {
          this.$refs.selectFilterEmpresa.selectDefaultItem();
          this.selectedEmpresa = [];
          this.empresasDropdown = [];
          this.disabledEmpresa = true;

          if(this.selectedNegocio.length > 0){
            const selectedGroupIds = this.selectedGrupo.map(group => group.id)
            const selectedNegociosIds = this.selectedNegocio.map(negocio => negocio.id)

            const params = {
              idsGrupos: selectedGroupIds,
              negocio: selectedNegociosIds,
            }

            await this.$http.get(this.$api.empresasGrupoNegocio(), { params })
            .then(response => {
              if (response.status === 200) {
                this.empresasDropdown = response.data.map(empresa => ({
                  id_empresa: empresa.id_empresa,
                  documento: empresa.documento,
                  documento_formatado: this.formatDocument(empresa.documento),
                  nome_empresa: empresa.nome_empresa,
                  razao_social: empresa.razao_social,
                  doc_filter: this.formatDocument(empresa.documento) + ' ' + empresa.documento
                }));
              }
            });

            this.disabledEmpresa = false
          }
        },

        async getNegociosPorGrupos() {this.selectedNegocio = [];
          this.negociosDropdown = [];
          this.$refs.selectFilterEmpresa.selectDefaultItem();
          this.selectedEmpresa = [];
          this.empresasDropdown = [];


          const allBusinessOption = this.$refs.MultipleSelectFilterNegocio.$el.querySelector("#optionsAllBusiness")
          allBusinessOption.classList.add("filter-display-disabled")
          this.disabledNegocio = true
          this.disabledEmpresa = true;

          if(this.selectedGrupo.length > 0){
            const selectedGroupIds = this.selectedGrupo.map(group => group.id)

            const params = {
              idGrupo: selectedGroupIds,
            }

            await this.$http.get(this.$api.grupoNegocio(), { params })
            .then(response => {
              if (response.status === 200) {
                this.negociosDropdown = response.data.map(negocio => ({
                  id: negocio.id_grupo_negocio,
                  name: negocio.descricao,
                }));

                allBusinessOption.classList.remove("filter-display-disabled")
                this.disabledNegocio = false
              }
            });
          }
        },

        formatDocument(document) {
            if (document.length === 14) {
                return this.$options.filters.VMask(document, '##.###.###/####-##')
            }
            if (document.length === 11) {
                return this.$options.filters.VMask(document, '###.###.###-##')
            }
            return document
        },
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.dashboard-filter {
    position: relative;
	margin-bottom: responsive-height(25px);
}

/* Notification Animation */
.notification-enter, .notification-leave-to {
    opacity: 0; 
}

.notification-move, .notification-enter-active {
    transition: all .3s;
}

.notification-leave-active {
    position: absolute;
    width: calc(100% - 4.6vh);
    transition: all .3s;
}
</style>