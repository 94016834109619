import $http from '@/libs/api';
import $api from '@/router/rotasAPI';
import moment from "moment/moment";
import * as XLSX from 'xlsx';

export async function downloadComparativeData(filters, api = $api.painelEmpresaDashboardComparativo()) {
        let companyComparative = [];
    
        await $http
        .get(api, {params: filters})
        .then(({ data }) => {
            companyComparative = data.dados_paginados
        });

        let companyList = companyComparative.filter(empresa => empresa.nome != null)

        if(filters.adressSheet){
            const adresses = companyComparative.filter(empresa => empresa.nome == null)
            adresses.map((item) => {
                let company = companyList.findIndex(adress => adress.id == item.id)

                companyList.splice(company + 1, 0, item)
            })
        }

        const dataHoraAtual = moment().format('DD/MM/YYYY HH:mm:ss');
        const dataAtual = moment().format('DD-MM-YYYY');
        const horaAtual = moment().format('HH-mm-ss');
       
        let data = []
        let formattedData = []
        let dadosConsolidados = filters.selectedCampaign.length > 1 ? ['Dados consolidados para ' + filters.selectedCampaign.length + ' campanhas'] : null;
        let header = ['Nome Fantasia', 'Razão Social', 'CNPJ', 'Doses Solicitadas', 'Doses Restantes', 'Pessoas Vacinadas', 'Andamento Vacinação', 'Pessoas Cadastradas', 'Pessoas Não Vacinadas', 'Vacinados Total', 'Data e Hora']

        let enderecos = companyList.filter(empresa => empresa.idEmpresaEndereco != null);
        if(filters.adressSheet && enderecos.length > 0){
            data = enderecos;
            data = data.map(item => {
                const empresaInfo = companyComparative.find(empresa => empresa.id == item.id && empresa.nome)
                if(empresaInfo){
                    formattedData.push({
                        ...item,
                        nome:empresaInfo.nome,
                        nomeFantasia: empresaInfo.nomeFantasia,
                        cnpj: empresaInfo.cnpj
                    })
                }
            })
            header = ['Nome Fantasia', 'Razão Social', 'CNPJ', 'Identificador do Endereço', 'CEP', 'Rua/Avenida','Numero','Bairro','Estado','Cidade', 'Doses Solicitadas', 'Doses Restantes', 'Pessoas Vacinadas', 'Andamento Vacinação', 'Data e Hora']
            formattedData = formattedData.map(empresasComparativo => [
                empresasComparativo.nomeFantasia,
                empresasComparativo.nome,
                empresasComparativo.cnpj,
                empresasComparativo.endereco.descricao,
                empresasComparativo.endereco.cep,
                empresasComparativo.endereco.logradouro,
                empresasComparativo.endereco.numero,
                empresasComparativo.endereco.bairro,
                empresasComparativo.endereco.uf_adesao,
                empresasComparativo.endereco.cidade,
                empresasComparativo.doses_solicitadas,
                empresasComparativo.doses_restantes,
                empresasComparativo.pessoas_vacinadas,
                parseFloat(empresasComparativo.andamento_vacinacao).toFixed(0) + "%",
                dataHoraAtual 
            ]);
        }else{
            formattedData = companyList.map(empresasComparativo => [
                empresasComparativo.nomeFantasia,
                empresasComparativo.nome,
                empresasComparativo.cnpj,
                empresasComparativo.doses_solicitadas,
                empresasComparativo.doses_restantes,
                empresasComparativo.pessoas_vacinadas,
                parseFloat(empresasComparativo.andamento_vacinacao).toFixed(0) + "%",
                empresasComparativo.pessoas_cadastradas,
                empresasComparativo.pessoas_nao_vacinadas,
                parseFloat(empresasComparativo.vacinados_total).toFixed(0) + "%",
                dataHoraAtual 
            ]);
        }

        let ws = XLSX.utils.aoa_to_sheet([header, ...formattedData]);
        if (dadosConsolidados) {
          ws = XLSX.utils.aoa_to_sheet([dadosConsolidados, header, ...formattedData]);
        }

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Empresas');

        const columnWidths = [
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 25 }
        ];

        for (let i = 0; i < columnWidths.length; i++) {
            ws['!cols'] = columnWidths;
        }

        const nomeArquivo = `comparativos-empresas-data-${dataAtual}-hora-${horaAtual}.xlsx`;

        XLSX.writeFile(wb, nomeArquivo);
}

export function mountCustomParamsToRequest(data, pageSortData = null) {

    let empresas = [];

    if(data.empresa && Array.isArray(data.empresa)){
        empresas = data.empresa.filter(empresa => empresa.id_empresa != 'all').map(empresa => empresa.id_empresa);
    }else{
        empresas = data.empresa ? [data.empresa.id_empresa] : [];
    }

    let campanhas = [data.campanha.id_campanha];

    if(campanhas.includes('all')){
      campanhas = []
    }

    const dataRequest = {
        departamentos: data.departamento ? data.departamento.filter(dep => dep.id_departamento != 'all').map(depto => depto.id_departamento) : [],
        unidades: data.unidade ? data.unidade.map(unidade => unidade.id_unidade) : [],
        estados: data.estado ? data.estado.map(estado => estado.uf) : [],
        empresas: empresas,
        id_campanha: campanhas
    }
    
    dataRequest.grupos = data.grupo ? [data.grupo.id_grupo] : [];
    const params = {};

    Object.keys(dataRequest).forEach((key) => {
        if (Array.isArray(dataRequest[key])) {
            dataRequest[key].forEach((item, index) => {
                const paramsKey = `${key}`;
                if (!params[paramsKey]) {
                    params[paramsKey] = [];
                }
                params[paramsKey].push(item);
            });
        } else {
            params[key] = dataRequest[key];
        }
    });

    if (pageSortData) {
        params.colunaOrdenar = pageSortData.sortBy;
        params.ordem = pageSortData.order;
        params.pagina = pageSortData.currentPage;
        params.tamanho = pageSortData.defaultSize;
    }

    return params;
}