<template>
	<div>
		<tour :steps="tourSteps">
			<transition name="fade">
				<div v-if="!isLoading && !campaignExists" class="no-campaign">
					<img :src="require('@/assets/custom-icons/pages/dashboard/error-icon.png')" alt="Ícone Erro"
						class="error-icon">
					<h1>Adesão não encontrada</h1>
					<h6>Você não possui nenhuma empresa com adesões em campanha.</h6>
				</div>

				<div v-if="!isLoading && campaignExists" id="company-dashboard">
					<DashboardHeader :title="dashboardTitle" :updatedAt="updatedAt" />

      <DashboardFilter
        ref="filterComponent"
        v-model="selectedCampaign"
        :filter-fields="filterFields"
        :loadingButton="loadingButton"
        @filterUpdade="getDashboardData($event)"
        @paramsUpdate="populateFilterFields($event)"
      />

					<b-alert
						:show="filterFields.allCompanies.length == 0"
						class="mb-2"
						variant="danger"
					>
						<div class="alert-body">
						<span>Não há dados para serem listados com esses filtros.</span>
						</div>
					</b-alert>

      <button
        class="dash-btn"
        :class="{ active: dashboardView == 1 }"
        @click="changeDashboardView(1)"
      >Visão
        Geral</button>
      <button
        class="dash-btn"
        :class="{ active: dashboardView == 2 }"
        @click="changeDashboardView(2)"
      >Comparativo</button>

					<div class="dashboard-general-data" v-show="dashboardView == 1" >
						<div class="general-data row-2">
							<VaccinationDonutChartPanel :vaccinationData="VaccinationDonutChartPanelData" />
							<div class="align-blocks">
								<VaccinationAbsoluteDataPanel v-for="(panel, i) in vaccinationAbsoluteData" :key="i"
									:absoluteData="vaccinationAbsoluteData[i]" />
							</div>
						</div>
						<div class="general-data row-3">
							<EvolutionOrdersVaccinationPanel :data="evolutionData" />
						</div>
					</div>

					<CompanyComparativeDataPanel
					:adressSheet="nacional"
					@sortData="getDashboardData(filterParams, $event)"
          @downloadSheet="downloadSheet($event)"
					:empresasComparativo="empresasComparativo"
          :selectedCampaign="selectedCampaign"
          :downloadingSheet="downloadingSheet"
					v-show="dashboardView == 2" />
				</div>
			</transition>
		</tour>
	</div>

</template>

<script>
import { BAlert } from 'bootstrap-vue'
import moment from 'moment'
import { getDashboardFilterOptions, getCompaniesData, getDashboardData, getDashboardDataComparativo, setParams } from '@/views/custom-pages/dashboard/company/requests'
import dashUtilsMixin from '../shared-mixins/dashUtilsMixin'
import DashboardHeader from '@/views/custom-pages/dashboard/shared-components/DashboardHeader.vue'
import DashboardFilter from './company-components/DashboardFilter.vue';
import VaccinationAbsoluteDataPanel from './company-components/VaccinationAbsoluteDataPanel.vue';
import VaccinationDonutChartPanel from './company-components/VaccinationDonutChartPanel.vue';
import VaccinationRangeChartPanel from './company-components/VaccinationRangeChartPanel.vue'
import EvolutionOrdersVaccinationPanel from './company-components/EvolutionOrdersVaccinationPanel.vue';
import CompanyComparativeDataPanel from './company-components/CompanyComparativeDataPanel.vue';
import tourMixin from '@/mixins/tourMixin';
import dashCompanyMixin from './mixins/dashCompanyTourMixin';
import { downloadComparativeData } from '@/views/custom-pages/dashboard/company/mixins/companyPanelFunctions.js';

export default {
	components: {
		BAlert,
		DashboardHeader,
		DashboardFilter,
		VaccinationAbsoluteDataPanel,
		VaccinationDonutChartPanel,
		VaccinationRangeChartPanel,
		EvolutionOrdersVaccinationPanel,
		CompanyComparativeDataPanel
	},
	mixins: [dashUtilsMixin, tourMixin, dashCompanyMixin],

  data() {
    return {
      isLoading: true,
      campaignExists: true,
      filterFields: {
        allGroups: [],
        allStates: [],
        allOperators: [],
        allCompanies: [],
      },
      nacional: true,
      dashboardTitle: 'Todas as minhas empresas',
      updatedAt: `${moment().format('HH:mm')} do dia ${moment().format('DD/MM/YYYY')}`,
      dashboardView: 1, // 1 = General   2 = Comparative

      vaccinationAbsoluteData: [
        { img: 'people-blue-icon', value: 0, label: ['Trabalhadores', 'Vacinados'] },
        { img: 'people-yellow-icon', value: 0, label: ['Dependentes', 'Vacinados'] },
        { img: 'people-green-icon', value: 0, label: ['Terceiros', 'Vacinados'] },
        { img: 'doses-left-icon', value: 0, label: ['Doses', 'Restantes'] },
      ],
      VaccinationDonutChartPanelData: {},
      vaccinationRangeData: {},
      evolutionData: {},
      empresasComparativo: [],
      allCampaigns: [],
      selectedCampaign: [],
      filterParams: {},
      loadingButton: false,
      downloadingSheet: false
    }
  },

	created() {
		if(this.loadData){
			this.loadCampaign()
		}
	},

	methods: {
		loadCampaign() {
            this.$http.get(this.$api.campanhaEmpresa()).then(({ data }) => {
                this.allCampaigns = data.map((campaign) => {
                    return {
                        id: campaign.id_campanha,
                        name: campaign.descricao,
                        nacional: campaign.tipo == 'Nacional',
                        startDate: campaign.cronograma_nacional_inicio ?? campaign.cronograma_regional_inicio
                    }
                });

				this.campaignExists = this.allCampaigns.length > 0
				if(this.campaignExists){
					this.getInitialDashboardData();
				}else{
					this.isLoading = false
					this.$emit("loadFinished", true)
				}
            });
        },
		changeDashboardView(view) {
			this.dashboardView = view;
		},

    async getInitialDashboardData(filters = null) {

      const filterParams = filters || {
        ids_campanhas: this.selectedCampaign.length > 0 ? this.selectedCampaign.map(campaign => campaign.id) : this.allCampaigns.map(campanha => campanha.id),
      }

      if (!this.selectedCampaign.nacional) {
        filterParams.uf_empresa = null
      }

      await this.populateFilterFields(filterParams)

      const { allCompanies } = this.filterFields

      const initialCompaniesId = allCompanies.length > 0 ? this.filterFields.allCompanies.map(company => company.id_empresa) : []
      const initialAdressesId = allCompanies.length > 0 ? this.filterFields.allCompanies.map(company => company.enderecos.map(adress => adress.id_empresa_endereco)).flat() : []

      const nacional = this.selectedCampaign.some(campaign => campaign.nacional)

      const startDate = this.selectedStartDate ? this.selectedStartDate : `01/01/${moment().year()}`;
      const endDate = this.selectedEndDate ? this.selectedEndDate : moment().format('DD/MM/YYYY');

      const params = {
        companies: initialCompaniesId,
        adresses: nacional ? initialAdressesId : null,
        uf: filterParams.uf_empresa,
        campaign: filterParams.ids_campanhas,
        nacional: nacional,
        negocio: filterParams.id_grupo_negocio,
        startDate: startDate,
        endDate: endDate,
      }

      await this.getDashboardData(params)
      this.$emit('loadFinished', true)
      this.isLoading = false
    },

    async populateFilterFields(params) {

      try {
        this.loadingButton = true
        const response = await getDashboardFilterOptions(params)
        const companies = await getCompaniesData(params)

        if(response.data !== undefined && Object.keys(response.data).length > 0) {
          this.filterFields.allGroups =await this.listagemGrupos(params)
          this.filterFields.allStates = response.data.estados
          this.filterFields.allOperators = response.data.operadores
          this.filterFields.allCampaigns = this.allCampaigns
          this.filterFields.allCompanies = companies.data
          this.loadingButton = false
        }
      } catch (error) {
        console.error('Erro ao buscar dados para popular filtro:', error)
      }
    },

    async listagemGrupos(params)
    {
      let cloneParams = Object.assign({}, params);
      delete cloneParams.id_grupo;
      const response = await getDashboardFilterOptions(cloneParams)
      return response.data.grupos;
    },
    async downloadSheet(pageSortData) {
        this.downloadingSheet = true
        let params = this.filterParams
        params = setParams(params);
        params.colunaOrdenar = pageSortData.sortBy;
        params.ordem = pageSortData.order;
        params.pagina = pageSortData.currentPage;
        params.tamanho = pageSortData.defaultSize;
        params.selectedCampaign = this.filterParams.campaign

        await downloadComparativeData(params, this.$api.getDashboardDataAdminEmpresaComparativo());
        this.downloadingSheet = false
    },
    async getDashboardData(filterParams, sortFilter = null) {

      if (filterParams.companies.includes('all')) {
        filterParams.companies = this.getInicialCompaniesIds()
      }
      
      this.filterParams = filterParams
      const params = { ...filterParams, ...sortFilter }

      if (params.companies.length == 0) {
        this.campaignExists = false
        return
      }

      try {
        this.loadingButton = true
        const geral = await getDashboardData(params)
        const comparativo = await getDashboardDataComparativo(params)

        const data = {
          geral: geral.data,
          comparativo: comparativo.data,
        }

        // Indicativos Superiores de Vacinação
        this.vaccinationAbsoluteData[0].value = data.geral.vacinacao.aplicadas.trabalhadores
        this.vaccinationAbsoluteData[1].value = data.geral.vacinacao.aplicadas.dependentes
        this.vaccinationAbsoluteData[2].value = data.geral.vacinacao.aplicadas.terceiros
        this.vaccinationAbsoluteData[3].value = data.geral.vacinacao.pendentes.colaboradores

        // Andamento da Vacinação (gráfico donut)
        this.VaccinationDonutChartPanelData = {
          dosesSolicitadas: data.geral.doses.solicitadas,
          dosesAplicadas: data.geral.doses.aplicadas,
        }

        // Alcance da Vacinação (gráfico semi-circulo)
        this.vaccinationRangeData = {
          totalColaborators: parseInt(data.geral.colaboradores_cadastrados),
          vaccinatedColaborators: parseInt(data.geral.doses.aplicadas),
        }

        const dataWeek2 = []
        const numWeek2 = []
        let subWeek2 = []
        let historicoCompleto2 = []

        // Evolução da Vacinação (gráfico de linha)
        for (let i = 0; i < data.geral.vacinacao.historico.length; i++) {
          dataWeek2[i] = data.geral.vacinacao.historico[i].quantidade
          numWeek2[i] = `Semana ${i + 1}`
        }
        subWeek2 = data.geral.vacinacao.historico_anos
        historicoCompleto2 = data.geral.vacinacao.historico_periodo_inicial.map((value, index) => `${value} até ${data.geral.vacinacao.historico_periodo_final[index]}`)

        this.evolutionData = {
          historicoCompleto: historicoCompleto2,
          numWeek: numWeek2,
          dataWeek: dataWeek2,
          subWeek: subWeek2,
        }

        this.empresasComparativo = data.comparativo
        this.nacional = params.nacional

        this.updatedAt = `${moment().format('HH:mm')} do dia ${moment().format('DD/MM/YYYY')}`
        this.loadingButton = false
      } catch (error) {
        console.error('Erro ao buscar dados do dashboard:', error)
      }
    },

    getInicialCompaniesIds() {
      const { allCompanies } = this.filterFields

      return allCompanies.length > 0 
          ? this.filterFields.allCompanies.map(company => company.id_empresa) 
          : []
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";

/* GENERAL */
.no-campaign {
	text-align: center;
	margin-top: responsive-height(140px);

	.error-icon {
		width: responsive-height(100px);
		height: responsive-height(100px);
		margin-bottom: responsive-height(25px);
	}
}

.align-blocks {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: responsive-height(25.17px) responsive-height(25.17px);
	flex: 1;
	height: responsive-height(373px) !important;

}

.dash-btn {
	padding: responsive-width(10px);
	margin-bottom: responsive-height(24px);
	background-color: transparent;
	color: $placeholder-color;
	font-size: responsive-height(14px);
	line-height: responsive-height(18px);
	font-weight: 500;
	border: 0;

	&:first-of-type {
		margin-right: responsive-width(20px);
	}

	&.active {
		color: $custom-blue;
		border-bottom: 1px solid $custom-blue;
	}
}

/* FILTER */
.dashboard-filter {
	margin-bottom: responsive-height(34px);
}

/* DATA */
.row-1 .panel {
	height: responsive-height(116px);
}

.row-2 .panel {
	height: responsive-height(372px);
}

.row-3 .panel {
	height: responsive-height(463px);
	padding: responsive-height(60px) responsive-width(72px);
	width: 100%;
}

.dashboard-comparative-data {
	margin-bottom: responsive-height(25px);
}

// MOBILE
@media only screen and (max-width: 780px) {
	.dashboard-filter {
		margin-bottom: mobile-responsive-height(20px);
	}

	.row-1 .panel {
		min-width: 100%;
	}

	.row-2 .panel {
		min-width: 100%;
		height: fit-content;
	}

	.row-3 .panel {
		flex-basis: 380px;
		padding: mobile-responsive-width(16px);
	}
}
</style>
